//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Toast } from "vant";
export default {
  data() {
    return {
      checks: 1,
      schoolNum: "",
      isClick: true,
      time: "获取验证码",
      phone: "",
      sms: "",
    };
  },
  methods: {
    // 获取验证码
    async gainCode() {
      console.log(this.phone);
      if (this.isClick) {
        if (
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
            this.phone
          )
        ) {
          // const res = await sendVerifyCode({ phone: this.phone }); // 获取验证码接口
          this.isClick = false;
          let s = 60;
          this.time = s + "s";
          let interval = setInterval(() => {
            s--;
            this.time = s + "s";
            if (s < 0) {
              this.time = "重新获取";
              this.isClick = true;
              clearInterval(interval);
            }
          }, 1000);
        } else {
          Toast.fail("请输入正确的手机号码");
        }
      }
    },
    goParentM() {
      this.$router.push("/parM");
    },
    goStudM() {
      this.$router.push("/StuM");
    },
  },
};
